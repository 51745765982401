import className from 'classnames';
import { FaWindows } from 'react-icons/fa';

import ConsolePage from '../../../../../layouts/console';
import { useCallback, useEffect, useRef } from 'react';
import Notice from '../../../../../components/notice';

import styles from './setup.module.scss';


export default function Setup(props) {
    const videoEl = useRef(null);
    const playVideo = useCallback(() => {
        videoEl.current.play();
    }, []);

    useEffect(() => {
        window.addEventListener('focus', playVideo);
        return () => window.removeEventListener('focus', playVideo);
    }, [playVideo]);

    return (
        <>
            <ConsolePage levels={['Live Transcription', 'Setup']}>
                <section className="section is-main-section">
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                <h4 className="subtitle is-4">Overview</h4>
                                <p className="mt-5 mb-6">bil-Malti’s live transcription service lets you live stream audio data and receive instant automatic transcriptions as you speak.</p>

                                <h4 className="title is-4 has-text-primary">bil-Malti Transcript Studio</h4>
                                <p className="my-5">With bil-Malti Transcript Studio you can customise the appearance of your transcript depending on your application. Whether it’s screen projection for a live conference or a broadcast event, we got you covered.</p>
                                <p className="my-5">To get started, download bil-Malti Transcript Studio and log in using your bil-Malti account.</p>

                                <div className={className('mt-4 mb-6', styles.downloadSection)}>
                                    <a className="button is-primary mt-5" href="https://bilmalti.s3.eu-central-1.amazonaws.com/apps/bil-Malti.exe"><FaWindows className="mr-4" /> Download</a>
                                    <p className="is-size-7 mt-2">Latest version 1.0.1.</p>
                                    <p className="has-text-grey is-size-7 mt-2">Requires Microsoft Windows 7 and up.</p>
                                </div>
                            </div>
                            <div className="column has-text-centered">
                                <div className={className(styles.video, 'mt-4')}>
                                    <video ref={videoEl} className="is-block p-0" autoPlay loop muted playsInline disableRemotePlayback>
                                        <source src="/live-demo.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6">
                            <Notice live={true} />
                        </div>
                    </div>
                </section>
            </ConsolePage>
        </>
    )
}
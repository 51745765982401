import classnames from 'classnames';
import { FaEnvelope, FaFacebook } from 'react-icons/fa';

import LegalDocuments from '../../enums/LegalDocuments';

import styles from './footer.module.scss';


export default function Footer({ setCurrentLegalDocument }) {
    return (
        <footer className={`${styles.footer} footer has-text-white p-5`}>
            <div className="container mt-4 mb-4">
                <div className={classnames('columns', styles.footerLayout)}>
                    <div className={classnames('column has-text-centered-mobile', styles.footerLogo)}>
                        <div className={classnames(styles.logoFooterImage)} />
                    </div>

                    <div className={classnames('column has-text-centered', styles.footerLegal)}>
                        <p className={styles.policies}>
                            <button className="button is-ghost has-text-white" onClick={() => setCurrentLegalDocument(LegalDocuments.CookiePolicy)}><small>Cookie Policy</small></button>&nbsp;|&nbsp;
                            <button className="button is-ghost has-text-white" onClick={() => setCurrentLegalDocument(LegalDocuments.PrivacyPolicy)}><small>Privacy Policy</small></button>&nbsp;|&nbsp;
                            <button className="button is-ghost has-text-white" onClick={() => setCurrentLegalDocument(LegalDocuments.TermsOfUse)}><small>Terms of Use</small></button>
                        </p>

                        <p className={styles.copyrightNotice}>
                            <small>Copyright © 2025 bilmalti.com.</small><br />
                            <small>All rights reserved.</small>
                        </p>
                    </div>

                    <div className={classnames('column has-text-right has-text-centered-mobile', styles.footerContact)}>
                        <a href="mailto:info@bilmalti.com" className="has-tooltip-active">
                            <FaEnvelope size={24} />
                        </a>
                        <a href="https://www.facebook.com/bilmalti.mt">
                            <FaFacebook size={24} className="ml-4" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}
